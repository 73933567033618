<template>
  <div class="d-flex">
    <v-icon>mdi-white-balance-sunny</v-icon>
    <v-switch v-model="theme" @click="setTheme()" class="ml-1"></v-switch>
    <v-icon>mdi-weather-night</v-icon>
  </div>
</template>

<script>
export default {
  name: "ThemeSwitch",
  computed: {
    theme: {
      get() {
        return this.$store.state.setup.darkTheme;
      },
      set() {},
    },
  },
  methods: {
    setTheme() {
      const isDark = !this.$vuetify.theme.dark;
      this.$store.dispatch("setup/setTheme", isDark);
    },
  },
};
</script>
