<template>
  <v-app class="mt-0">
    <loader
        v-if="overlay_loader_1"
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="spinning"
    ></loader>
    <loader
        v-if="overlay_loader_2"
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="16"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="loading"
        style="z-index: 10000;"
    ></loader>
    <v-container style="max-width: 1200px">
      <v-row
          justify="space-between"
          align="start"
          class="mb-3"
          v-if="showHeader"
      >
        <v-col>
          <h1 class="text-h4 font-weight-light">
            {{ getConfiguratorTitel }}
          </h1>
          <div class="text-subtitle-1 font-weight-light">
            {{ getConfiguratorSubTitel }}
          </div>
        </v-col>
        <v-col
            cols="auto"
            class="d-flex align-start mt-1"
            v-if="showHeaderOptions"
        >
          <LocaleChanger class="mr-4 mt-1"/>
          <ThemeSwitch class="mt-n4"/>
        </v-col>
      </v-row>
      <router-view/>
    </v-container>
    <v-overlay v-model="this.overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="text-center">
      <v-dialog
          v-model="getErrorDialogShow"
          width="500"
          transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="red" dark>
            {{ getErrorDialogTitleText }}
          </v-toolbar>
          <v-card-text>
            <p class="mt-5">
              {{ getErrorDialogBodyText }}
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="$store.dispatch('setup/setErrorDialogShow', false)"
            >
              {{ getErrorDialogButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <default-modal
        :isOpen="getModalState('modalAfterAddToCart')"
        :content="getModalContent('modalAfterAddToCart')"
        @close="closeModal('modalAfterAddToCart')"
    ></default-modal>
  </v-app>
</template>

<script>
import LocaleChanger from "@/components/layout/LocaleChanger";
import ThemeSwitch from "@/components/layout/ThemeSwitch";
import {mainMixin} from "@/mixins/mainMixin";

import DefaultModal from './components/modals/defaultModal.vue';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FensterKonfigurator",

  components: {LocaleChanger, ThemeSwitch, DefaultModal},
  mixins: [mainMixin],
  data() {
    return {
      dialog: true
    };
  },
  computed: {
    ...mapGetters({
      getModalState: 'setup/getModalState',
      getModalContent: 'setup/getModalContent'
    }),
    getConfiguratorTitel() {
      return this.$store.state.productData.configurator.titel;
    },
    getConfiguratorSubTitel() {
      return this.$store.state.productData.configurator.subTitel;
    },
    showHeader() {
      return this.$store.getters["setup/getShowHeader"];
    },
    showHeaderOptions() {
      return this.$store.getters["setup/getShowHeaderOptions"];
    },
    overlay_loader_1() {
      return this.$store.getters["setup/getOverlayLoader1"];
    },
    overlay_loader_2() {
      return this.$store.getters["setup/getOverlayLoader2"];
    },
    getErrorDialogShow() {
      return this.$store.getters["setup/getErrorDialogShow"];
    },
    getErrorDialogTitleText() {
      return this.$store.getters["setup/getErrorDialogTitleText"];
    },
    getErrorDialogBodyText() {
      return this.$store.getters["setup/getErrorDialogBodyText"];
    },
    getErrorDialogButtonText() {
      return this.$store.getters["setup/getErrorDialogButtonText"];
    },
  },
  methods: {
    ...mapActions({
      closeModal: 'setup/closeModal'
    })
  }
};
</script>

<style>
.v-expansion-panel-header {
  height: 55px !important;
}
</style>