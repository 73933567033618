<template>
  <v-dialog
      v-model="localIsOpen"
      max-width="800px"
      @input="onDialogInput"
  >
    <v-card>
      <v-card-text>
        <!-- Animation anzeigen, wenn `showContent` false ist -->
        <div v-if="!showContent" class="cart-animation">
          <!-- Text oberhalb der Animation -->
          <v-row>
            <v-col cols="12">
              <div class="animation-text">Das Produkt wird in den Warenkorb gelegt...</div>
            </v-col>
            <v-col cols="12" style="margin-bottom: 30px;">
              <!-- Container für Produktbild und Warenkorbsymbol -->
              <div class="product-cart-container">
                <!-- Produktbild, das animiert wird -->
                <div id="product-image-container" class="image-container">
                  <div v-html="productSVG" class="product-image"></div>
                </div>

                <!-- Warenkorbsymbol rechts -->
                <div id="cart-container" class="cart-icon-container">
                  <v-img :src="imageNativeURL('cart_transparent.png')" alt="go_to_cart" width="50"></v-img>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Den eigentlichen Inhalt anzeigen, wenn `showContent` true ist -->
        <transition name="fade">
          <div v-show="showContent">
            <p style="font-size: 18px; padding-top: 20px;">Was soll als nächstes geschehen? Wählen Sie aus folgenden Optionen.</p>

            <v-row>

              <!-- Card: Aktualisiert die Seite -->
              <v-col cols="4">
                <v-card @click="reloadPage" class="hover-card card-letwork-modal">
                  <v-img :src="imageNativeURL('konfiguration_plus_renew.png')" alt="renew_configuration"></v-img>
                  <v-card-text class="card-text">Ein neues Fenster von Anfang an konfigurieren.</v-card-text>
                </v-card>
              </v-col>

              <!-- Card: Schließt das Modal -->
              <v-col cols="4">
                <v-card @click="clearValueAndCloseModal" class="hover-card card-letwork-modal">
                  <v-img :src="imageNativeURL('konfiguration_plus.png')" alt="same_configuration"></v-img>
                  <v-card-text class="card-text">Die aktuelle Konfiguration für neues Fenster nutzen.</v-card-text>
                </v-card>
              </v-col>

              <!-- Card: Öffnet einen bestimmten Link -->
              <v-col cols="4">
                <v-card @click="openLink()" class="hover-card card-letwork-modal">
                  <v-img :src="imageNativeURL('cart.png')" alt="go_to_cart"></v-img>
                  <v-card-text class="card-text">Warenkorb anzeigen</v-card-text>
                </v-card>
              </v-col>

            </v-row>
          </div>
        </transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mainMixin} from "@/mixins/mainMixin";
import {mapActions} from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localIsOpen: this.isOpen,
      showContent: false,  // Steuerung der Sichtbarkeit des Modalinhalts
      animationDuration: 2000, // Dauer der Animation in Millisekunden (einstellbar)
      productSVG: ''  // Das SVG des Produkts wird hier gespeichert
    };
  },
  mixins: [mainMixin],
  watch: {
    isOpen(newVal) {
      this.localIsOpen = newVal;
      if (newVal) {
        this.startAnimation();
      }
    }
  },
  methods: {
    ...mapActions({
      clearStoreValue: 'configuration/deleteUuid'
    }),
    clearValueAndCloseModal() {
      this.clearStoreValue();
      this.closeModal();
    },
    closeModal() {
      this.localIsOpen = false;
      this.showContent = false; // Setze showContent zurück, wenn das Modal geschlossen wird
      this.$emit('close');
    },
    onDialogInput(newVal) {
      if (!newVal) {
        this.closeModal();
      }
    },
    openLink() {
      const cartLink = this.$store.getters['setup/getCartUrl'];
      window.open(cartLink, '_self');
    },
    reloadPage() {
      window.location.reload();
    },
    startAnimation() {
      this.showContent = false;
      // Ziehe das SVG aus dem Container mit der ID 'window_configuration'
      const svgElement = document.querySelector('#window_configuration');
      if (svgElement) {
        this.productSVG = svgElement.innerHTML; // Extrahiere das SVG und speichere es
      }
      setTimeout(() => {
        this.showContent = true; // Zeige den eigentlichen Inhalt nach der Animation
      }, this.animationDuration); // Animation dauert so lange wie definiert
    }
  }
};
</script>

<style>
.cart-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 300px; /* Höhe des Bereichs für die Animation */
  text-align: center;
}

.animation-text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 21px;
  color: #333;
}

.product-cart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 150px; /* Höhe des Containers für Bild und Icon */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Bild ganz links ausrichten */
  align-items: center;
  animation: moveToCart 2s ease-in-out forwards;
}

.product-image {
  width: 100px; /* Startgröße des Produktbildes */
}

.cart-icon-container {
  margin-left: 100px;
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Symbol ganz rechts ausrichten */
  align-items: center;
}

.cart-icon {
  font-size: 64px !important;
}

@keyframes moveToCart {
  0% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(375px) scale(0.5);
    opacity: 0;
  }
}

/* Übergangseffekt für den Modalinhalte */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Hover-Effekt für Karten */
.hover-card {
  cursor: pointer;
  transition: transform 0.3s;
}
.hover-card:hover {
  transform: scale(1.05);
}

.card-text {
  font-size: 16px !important;
}

.card-letwork-modal{
 min-height: 310px !important;
}

@media (max-width: 960px) {
  .card-text {
    font-size: 12px !important;
  }
}

@media (min-width: 960px) {
  .card-text {
    font-size: 16px !important;
  }
}

</style>