import { i18n } from "@/plugins/i18n";
import Vuetify from "../../plugins/vuetify";
import Vue from "vue";

export const namespaced = true;

export const state = {
  apiLanguageId: document.getElementById("app").dataset.api_language,
  apiConfiguratorId: document.getElementById("app").dataset.configurator_id,
  apiConfiguratorVersion:
    document.getElementById("app").dataset.configurator_version,
  apiClientId: document.getElementById("app").dataset.client_id,
  configuratorProductId:
    document.getElementById("app").dataset.configurator_product_id,
  shopProductId: document.getElementById("app").dataset.shop_product_id,
  title: document.getElementById("app").dataset.title,
  darkTheme: false,
  overlay_loader_1: false,
  overlay_loader_2: false,
  overlay_loader_3: false,
  showHeader: document.getElementById("app").dataset.show_header,
  showHeaderOptions: document.getElementById("app").dataset.show_header_options,
  loadMaterialId: document.getElementById("app").dataset.load_material_id,
  loadSeriesId: document.getElementById("app").dataset.load_series_id,
  loadProductId: document.getElementById("app").dataset.load_product_id,
  loadProductType: document.getElementById("app").dataset.load_product_type,
  loadDivisionId: document.getElementById("app").dataset.load_division_id,
  uuid: document.getElementById("app").dataset.uuid,
  deleteUuid: document.getElementById("app").dataset.delete_uuid,
  reloadProduct: document.getElementById("app").dataset.reload_product,
  reloadProductId: document.getElementById("app").dataset.reload_product_id,
  reloadDivisionId: document.getElementById("app").dataset.reload_product_division_id,
  reloadProductOpeningId: document.getElementById("app").dataset.reload_product_opening_id,
  reloadProductOpeningTab: document.getElementById("app").dataset.reload_product_opening_tab,
  quickDivision: document.getElementById("app").dataset.quick_division,
  quickOpening: document.getElementById("app").dataset.quick_opening,
  quickWidth: document.getElementById("app").dataset.quick_width,
  quickHeight: document.getElementById("app").dataset.quick_height,
  quickAddons: document.getElementById("app").dataset.quick_addons,
  iframeMode: document.getElementById("app").dataset.iframe_mode,
  configuratorJson:document.getElementById("app").dataset.product_configuration_json,
  productDataJson: document.getElementById("app").dataset.product_data_json,
  productSetupJson: document.getElementById("app").dataset.product_setup_json,
  useAddToCartModal: document.getElementById("app").dataset.use_add_to_cart_modal,
  cartUrl: document.getElementById("app").dataset.cart_url,
  errorDialogShow: false,
  errorDialogTitleText: "",
  errorDialogBodyText: "",
  errorDialogButtonText: "",
  modals: {}
};

//console.log(state.setShopProductId);

export const mutations = {
  SET_MODAL_STATE(state, { id, isOpen, content }) {
    state.modals = {
      ...state.modals,
      [id]: { isOpen, content }
    };
  },
  SET_RELOAD_PRODUCT_TO_NULL(state, payload) {
    Vue.set(state, "reloadProduct", payload);
  },
  SET_LOCALE(state, locale) {
    i18n.locale = locale;
    state.apiLanguageId = i18n.t("app.apiLanguage");
  },
  SET_THEME(state, isDark) {
    Vuetify.framework.theme.dark = isDark;
    state.darkTheme = isDark;
  },
  SET_OVERLAY_LOADER_1(state, isOn) {
    state.overlay_loader_1 = isOn;
  },
  SET_OVERLAY_LOADER_2(state, isOn) {
    state.overlay_loader_2 = isOn;
  },
  SET_ERROR_DIALOG_SHOW(state, isOn) {
    state.errorDialogShow = isOn;
  },
  SET_ERROR_DIALOG_TITLE_TEXT(state, text) {
    state.errorDialogTitleText = text;
  },
  SET_ERROR_DIALOG_BODY_TEXT(state, text) {
    state.errorDialogBodyText = text;
  },
  SET_ERROR_DIALOG_BUTTON_TEXT(state, text) {
    state.errorDialogButtonText = text;
  },
};
export const actions = {
  openModal({ commit }, { id, content }) {
    commit('SET_MODAL_STATE', { id, isOpen: true, content });
  },
  closeModal({ commit }, id) {
    commit('SET_MODAL_STATE', { id, isOpen: false, content: '' });
  },
  setReloadProductToNull({ commit }) {
    commit("SET_RELOAD_PRODUCT_TO_NULL", 0);
  },
  setLocale({ commit }, locale) {
    commit("SET_LOCALE", locale);
  },
  setTheme({ commit }, isDark) {
    commit("SET_THEME", isDark);
  },
  setOverlayLoader1({ commit }, isOn) {
    commit("SET_OVERLAY_LOADER_1", isOn);
  },
  setOverlayLoader2({ commit }, isOn) {
    commit("SET_OVERLAY_LOADER_2", isOn);
  },
  setErrorDialogShow({ commit }, isOn) {
    commit("SET_ERROR_DIALOG_SHOW", isOn);
  },
  setErrorDialogTitleText({ commit }, text) {
    commit("SET_ERROR_DIALOG_TITLE_TEXT", text);
  },
  setErrorDialogBodyText({ commit }, text) {
    commit("SET_ERROR_DIALOG_BODY_TEXT", text);
  },
  setErrorDialogButtonText({ commit }, text) {
    commit("SET_ERROR_DIALOG_BUTTON_TEXT", text);
  },
};
export const getters = {
  getModalState: (state) => (id) => state.modals[id]?.isOpen || false,
  getModalContent: (state) => (id) => state.modals[id]?.content || '',
  getAttrDataTitle() {
    return state.title;
  },
  getAttrDataConfiguratorId() {
    return state.apiConfiguratorId;
  },
  getShowHeader() {
    return state.showHeader;
  },
  getShowHeaderOptions() {
    return state.showHeaderOptions;
  },
  getApiConfiguratorVersion() {
    return state.apiConfiguratorVersion;
  },
  getConfiguratorJson() {
    return state.configuratorJson;
  },
  getSetupJson() {
    return state.productSetupJson;
  },
  getReloadProduct() {
    return state.reloadProduct;
  },
  getQuickWidth() {
    return state.quickWidth;
  },
  getQuickHeight() {
    return state.quickHeight;
  },
  getIframeMode() {
    return state.iframeMode;
  },
  getReloadProductId() {
    return state.reloadProductId;
  },
  getLoadMaterialId() {
    return state.loadMaterialId;
  },
  getLoadSeriesId() {
    return state.loadSeriesId;
  },
  getLoadProductId() {
    return state.loadProductId;
  },
  getUUID() {
    return state.uuid;
  },
  getDeleteUuid() {
    return state.deleteUuid;
  },
  getUseAddToCartModal() {
    return state.useAddToCartModal;
  },
  getCartUrl() {
    return state.cartUrl;
  },
  getProductDataJson() {
    return state.productDataJson;
  },
  getOverlayLoader1() {
    return state.overlay_loader_1;
  },
  getOverlayLoader2() {
    return state.overlay_loader_2;
  },
  getErrorDialogShow() {
    return state.errorDialogShow;
  },
  getErrorDialogTitleText() {
    return state.errorDialogTitleText;
  },
  getErrorDialogBodyText() {
    return state.errorDialogBodyText;
  },
  getErrorDialogButtonText() {
    return state.errorDialogButtonText;
  },
};
