<template>
  <div>
    <v-btn-toggle v-model="langSwitch">
      <v-btn
        v-for="locale in availableLocales"
        :key="locale"
        @click="setLocale(locale)"
        :to="{
          name: 'Index',
          params: { locale: locale },
        }"
        v-text="locale"
        depressed
        small
      >
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "LocaleChanger",
  data() {
    return {
      langSwitch: this.$i18n.locale,
    };
  },
  computed: {
    availableLocales() {
      return Object.keys(this.$i18n.messages);
    },
  },
  methods: {
    setLocale(locale) {
      this.$store.dispatch("setup/setLocale", locale);
      this.$router.go();
    },
  },
};
</script>
