import BackendService from "@/services/BackendService";
import {i18n} from "@/plugins/i18n";
import Vue from "vue";
import axios from "axios";
//import axios from "axios";

const camelize = (str) => {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
};

export const namespaced = true;

const filterCategoryItems = (category, config) => {
    // TODO design this properly.. potentially via "valid_parent_ids" arrays
    let items;

    // create an array of valid parent_ids and figure out the parent category
    const valid_parent_ids = state.model?.[category]?.items?.map(
        (i) => i.parent_icon_id
    );
    const parent_category_name = state.model?.[category]?.items
        ?.map((i) => i.parent_icon_category_name)
        .find((c) => c !== "");

    // get the currently selected parent_id from the store
    const selected_parent_id = config?.[parent_category_name]?.id;

    // is there a parent restriction set, somewhere?
    const restricted = valid_parent_ids?.some((i) => i > 0);

    if (restricted) {
        // if yes, filter out all items that dont have the restricted parent_id
        // or parent_id = 0 set
        items = JSON.parse(
            JSON.stringify(state.model_cache?.[category]?.items)
        )?.filter((i) => {
            return i.parent_icon_id === selected_parent_id || i.parent_icon_id === 0;
        });
    } else {
        // if there isnt a restriction, give back all items
        items = JSON.parse(JSON.stringify(state.model_cache?.[category]?.items));
    }
    return items;
};

export const state = {
    filterOrder: [
        "profile_designs",
        "openings",
        "decor_inside",
        "decor_outside",
        "glazing",
        "spacers",
        "glazing_bars",
        "sealing_color",
        "security_packages",
        "windowsill_connections",
        "equipment",
        "frame_extensions",
    ],
    // this should only be temporary
    // set the sequence in Model.vue
    tempLookupTable: {
        materials: {nextInLine: "series", nextHtmlId: ""},
        series: {nextInLine: "profiles", nextHtmlId: ""},
        profiles: {nextInLine: "model", nextHtmlId: ""},
        profile_designs: {nextHtmlId: "#divisions"},
        openings: {nextHtmlId: "#dimensions"},
        decor_inside: {nextHtmlId: "#decor_outside"},
        decor_outside: {nextHtmlId: "#glazing"},
        glazing: {nextHtmlId: "#spacers"},
        spacers: {nextHtmlId: "#ornamental_glazing"},
        ornamental_glazing: {nextHtmlId: "#glazing_bars"},
        glazing_bars: {nextHtmlId: "#glazing_bars_divisions"},
        sealing_color: {nextHtmlId: "#security_packages"},
        security_packages: {nextHtmlId: "#windowsill_connections"},
        windowsill_connections: {nextHtmlId: "#equipment"},
        equipment: {nextHtmlId: "#frame_extensions"},
        frame_extensions: {},
    },
    scrolling: {
        enabled: false, // scrolling to the next category
    },
    loading: {},
    enabledElements: {},
    materials: {
        items: null,
        menu: null,
        enabled: true,
    },
    series: {
        items: null,
        menu: null,
        enabled: true,
    },
    configurator: {
        titel: '',
        subTitel: '',
    },
    profiles: {
        items: null,
        menu: null,
        enabled: false,
    },
    model: {
        enabled: false,
    },
    model_cache: {
        enabled: false,
    },
    item_number: "",
    auto_select_items: {},
    dimensions: {
        menu: [
            {
                label_menu: i18n.t("dimension.labelMenu"),
                description_short_menu: i18n.t("dimension.descriptionShortMenu"),
                description_long_menu: i18n.t("dimension.descriptionLongMenu"),
                thumbnail: null,
            },
        ],
        enabled: false,
    },
    opening_dimensions: {
        menu: [
            {
                label_menu: i18n.t("opening_dimension.labelMenu"),
                description_short_menu: i18n.t(
                    "opening_dimension.descriptionShortMenu"
                ),
                description_long_menu: i18n.t("opening_dimension.descriptionLongMenu"),
                thumbnail: null,
            },
        ],
        enabled: true,
    },
    customerConfigTemp: null,
    setupTemp: null
};

export const mutations = {
    TOGGLE_NEXT_IN_LINE(state, elementType) {
        if (state[elementType]) state[elementType].enabled = true;
    },
    SET_CONFIGURATOR_DATA(state, payload) {
        state[payload.target].titel = payload.data[0].label_menu;
        state[payload.target].subTitel = payload.data[0].description_short_menu;
    },
    SET_MENU_DATA(state, payload) {
        state[payload.target].menu = payload.data;
    },
    SET_PRODUCT_DATA(state, payload) {
        state[payload.target].items = payload.data.items;
        state[payload.target].menu = payload.data.menu;
    },
    SET_PRODUCT_ITEMS(state, payload) {
        state[payload.target].items = payload.data.items;
    },
    SET_MODEL_DATA(state, payload) {
        state.enabledElements[payload.caller] = [];
        // the model api request sends a whole bunch of attributes at the same time
        // so we iterate through them, extract each and set the state
        for (const [key, value] of Object.entries(payload.data.categories)) {
            // workaround to pluralize (sorta) the key name
            // const myKey = key.endsWith("s") ? key : `${key}s`;

            // reactively create the category key
            Vue.set(state.model, key, {
                items: null,
                menu: null,
                isOptional: false,
                multipleChoice: false,
                position: null,
                standardItem: null,
            });

            // set all items
            state.model[key].items = Object.values(value.items);
            state.model[key].isOptional = !!value.is_optional;
            state.model[key].multipleChoice = !!value.multiple_choice;
            state.model[key].position = value.position;
            state.model[key].standardItem = value.standard_item;

            // extract the menu items for the accordion and make it compatible
            // with the other menu items (one object that holds it inside an array)
            state.model[key].menu = [
                Object.keys(value)
                    .filter(
                        (key) =>
                            key === "label_menu" ||
                            key === "name" ||
                            key === "description_short_menu" ||
                            key === "description_long_menu"
                    )
                    .reduce(
                        (newObj, currKey) => ((newObj[currKey] = value[currKey]), newObj),
                        {}
                    ),
            ];

            // since we have now loaded the model, we
            // can now enable dimensions as well
            state.dimensions.enabled = true;
        }
        Vue.set(state, "item_number", payload.data.model.item_number);
        Vue.set(state, "model_cache", JSON.parse(JSON.stringify(state.model)));
    },
    SET_API_REQUEST(state, data) {
        state.loading = {...data};
    },

    // we "clean" out the current state and re-fill it
    // with only valid selections
    FILTER_MODEL_DATA(state, payload) {
        // // do not filter earlier categories (e.g. do not filter / reset dimensions
        // // when selecting a decor
        // const callerIndex = state.filterOrder.findIndex(
        //   (f) => f === payload.caller
        // );
        // // eslint-disable-next-line no-unused-vars
        // const categoriesToFilter =
        //   callerIndex === -1
        //     ? state.filterOrder
        //     : state.filterOrder.slice(callerIndex);

        state.filterOrder.forEach((o) => {
            const newItems = filterCategoryItems(o, payload.customerConfig);
            const newItemIds = newItems.map((i) => i.id);
            const currentItemIds = state.model?.[o]?.items?.map((i) => i.id);

            // only update the model data if a new ID came into play
            // this fixes "jumping" items when a normally outside the current
            // visibility item was selected, which would now jump out of sight
            // as its order would be reset by the following operation
            if (currentItemIds.sort().toString() !== newItemIds.sort().toString()) {
                Vue.set(state.model?.[o], "items", newItems);
            }
        });
    },
    SETUP_DIMENSIONS(state, {payload}) {
        const minMax = {
            min_height: Number(payload?.min_height),
            max_height: Number(payload?.max_height),
            min_width: Number(payload?.min_width),
            max_width: Number(payload?.max_width),
            default_width: Number(payload?.default_width),
            default_height: Number(payload?.default_height),
            opening_code: payload?.opening_code,
            image: payload?.image,
        };
        Vue.set(state.dimensions, "min_max_values", minMax);
    },
    MOVE_ITEM_TO_STAY_VISIBLE(state, payload) {
        // do we have to deal with a model category?
        const isCategory = !!state.model?.[payload.itemType]?.items;
        let itemId, itemIndex, items, item;

        // if this is indeed a model category, find the item in there
        if (isCategory) {
            Vue.set(
                state.model?.[payload.itemType],
                "items",
                JSON.parse(
                    JSON.stringify(filterCategoryItems(payload.itemType, payload.config))
                )
            );
            items = state.model?.[payload.itemType]?.items;
            itemId = payload.config?.[payload.itemType]?.id;
            itemIndex = items?.findIndex((i) => i.id === itemId);

            // if not, we can find the item somewhere in the state root
        } else {
            items = state?.[payload.itemType]?.items;
            itemId = payload.config?.[payload.itemType]?.id;
            itemIndex = items?.findIndex((i) => i.id === itemId);
        }

        // give me the currently selected item and it's index
        item = items?.find((i) => i.id === itemId);
        itemIndex = items?.findIndex((i) => i.id === itemId);

        // if we have selected an item outside the max visible items
        // range (e.g. it would be hidden, if we re-hide the excess amount
        if (itemIndex >= payload.maxItems) {
            // set the item index to the last visible element
            const newIndex = payload.maxItems - 1;
            // remove the item from the items array
            items.splice(itemIndex, 1);
            // and re-insert it at the last visible element spot
            items.splice(newIndex, 0, item);
        }
    },
    TOGGLE_OPTIONAL_SELECTION(state, payload) {
        Vue.set(state.model?.[payload.itemType], "optionalSelected", payload.value);
    },
    SET_PRODUCT_DATA_FROM_EXIST_PRODUCT(state, payload) {
        //console.log(payload.model);
        Vue.set(state, "model", payload.model);
    },
    addToExistingArray(state, newArray) {
        state.auto_select_items.push(newArray);
    },
    SET_AUTO_SELECT_ITEM_ARRAY(state, payload) {
        Vue.set(state.auto_select_items, payload.caller, payload.items);
    }
};
export const actions = {
    setApiRequest({commit}, data) {
        commit("SET_API_REQUEST", data);
    },
    toggleNextInLine({commit, dispatch}, payload) {
        commit("TOGGLE_NEXT_IN_LINE", payload.target);

        // load the next data after a selection is made and there
        // actually exist a method to load the data with
        if (payload.selection !== null && payload.targetMethod !== null) {
            dispatch(payload.targetMethod, payload);
        }
        dispatch("configuration/setConfigData", payload, {root: true});
    },
    async unSetItem({commit, dispatch, rootState}, payload) {
        const nextInLine = rootState.productData.tempLookupTable[payload.caller];
        if (nextInLine?.nextInLine) {
            await commit("TOGGLE_NEXT_IN_LINE", nextInLine?.nextInLine);
            await dispatch(camelize(`get_${nextInLine?.nextInLine}`), payload);
        }

        payload.multipleChoice = false;
        if (rootState.productData.model[payload.caller]?.multipleChoice && rootState.productData.model[payload.caller]?.multipleChoice !== "undefined") {
            payload.multipleChoice = true;
        }

        if (payload.multipleChoice) {
            Vue.set(this.state.configuration.customerConfig[payload.caller], payload.selection.id, null);
        }

        if (payload.caller !== "materials") {
            await dispatch("configuration/setPriceFromApi", {}, {root: true});
        }
    },
    // eslint-disable-next-line no-unused-vars
    async setItem({commit, dispatch, rootState}, payload) {
        const nextInLine = rootState.productData.tempLookupTable[payload.caller];
        if (nextInLine?.nextInLine) {
            await commit("TOGGLE_NEXT_IN_LINE", nextInLine?.nextInLine);
            await dispatch(camelize(`get_${nextInLine?.nextInLine}`), payload);
        }

        payload.multipleChoice = !!(rootState.productData.model[payload.caller]?.multipleChoice && rootState.productData.model[payload.caller]?.multipleChoice !== "undefined");

        // auto select items
        const auto_select_data = {'caller': payload.caller, 'items': payload.selection.auto_select_items,}
        commit("SET_AUTO_SELECT_ITEM_ARRAY", auto_select_data);


        if (!payload.multipleChoice) {
            await dispatch("configuration/setConfigData", payload, {root: true});
        }

        // only call this if the customer isnt selecting a new profile or material
        if (payload.caller !== "materials" && payload.caller !== "profiles" && payload.caller !== "series" && !payload.multipleChoice) {
            await dispatch("reInitializeProductsAndCustomerConfig", payload);
        }

        // a (new) division or profile resets the current config,
        // so we need to re-setup the dimensions property
        if (payload.caller === "divisions") {
            await dispatch("setupDimensions", rootState.configuration.customerConfig.openings);
        }

        // a new opening resets the current config, so we need to re-setup the
        // dimensions property
        if (payload.caller === "openings") {
            await dispatch("setupDimensions", payload.selection);
        }

        if (payload.caller === "decor_outside" || payload.caller === "decor_inside") {
            let item = {item: payload.selection, category: payload.caller,};
            //console.log(item);
            await dispatch("check_decor", item);
        }

        if (payload.multipleChoice) {
            let selectionId = payload.selection.id;
            let selectionObject = {...this.state.configuration.customerConfig[payload.caller],};
            Vue.set(selectionObject, selectionId, {...payload.selection});
            Vue.set(this.state.configuration.customerConfig, payload.caller, selectionObject);
        }

        if (payload.caller !== "materials" && payload.caller !== "series") {
            await dispatch("configuration/setPriceFromApi", {}, {root: true});
        }

        // bring the next element into view
        if (nextInLine?.nextHtmlId && rootState.productData.scrolling.enabled && !payload.multipleChoice) {
            const nextElement = document.querySelector(nextInLine?.nextHtmlId);
            nextElement?.scrollIntoView({behavior: "smooth"});
        }
    },
    async check_decor(context, payload) {
        const customerConfig = {
            decor_inside_store: this.state.productData.model.decor_inside.items,
            decor_outside_store: this.state.productData.model.decor_outside.items,
            decor_inside_configuration:
            this.state.configuration.customerConfig.decor_inside,
            decor_outside_configuration:
            this.state.configuration.customerConfig.decor_outside,
            model: this.state.configuration.customerConfig.profiles.id,
            item: payload,
        };
        //console.log(customerConfig);
        //const response = await BackendService.getFinalPrice(this.state.configuration.customerConfig);
        let response = await axios.post(
            process.env.VUE_APP_API_URL + `check-decor`,
            customerConfig,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": process.env.VUE_APP_API_APP_KEY,
                }
            }
        );
        //console.log(response.data);
        Vue.set(this.state.configuration.customerConfig, "decor_inside", response.data.decor_inside_configuration);
        Vue.set(this.state.configuration.customerConfig, "decor_outside", response.data.decor_outside_configuration);
        Vue.set(this.state.productData.model.decor_inside, "items", response.data.decor_inside_store);
        Vue.set(this.state.productData.model.decor_outside, "items", response.data.decor_outside_store);
        Vue.set(this.state.productData.model_cache.decor_inside, "items", response.data.decor_inside_store);
        Vue.set(this.state.productData.model_cache.decor_outside, "items", response.data.decor_outside_store);
        //await commit("SET_PRICE_FROM_API", response.data.p_price);
    },
    async setupDimensions({commit, dispatch, rootState}, payload) {
        const config = rootState.configuration.customerConfig;
        await commit("SETUP_DIMENSIONS", {payload, config});
        await dispatch("configuration/prefillDimensionsData", {}, {root: true});
    },
    async getPrices() {
        try {
            const response = await BackendService.getFinalPrice();
            console.log(response.data);
        } catch (e) {
            console.log(e);
        }
    },
    async getMaterials({commit}) {
        try {
            const response = await BackendService.getMaterials();
            const payload = {target: "materials", data: response.data,};
            commit("SET_PRODUCT_DATA", payload);

            const payload_dimensions = {target: "dimensions", data: response.data.dimensions_menu,};
            commit("SET_MENU_DATA", payload_dimensions);

            const payload_series = {target: "series", data: response.data.series_menu,};
            commit("SET_MENU_DATA", payload_series);

            const payload_configurator = {target: "configurator", data: response.data.configurator_menu,};
            commit("SET_CONFIGURATOR_DATA", payload_configurator);

        } catch (e) {
            console.log(e);
        }
    },
    async getProfiles({commit}, payload) {
        try {
            const response = await BackendService.getProfiles(payload.selection?.id);
            let profileItems = response.data;
            //profileItems.sort((a, b) => a.position - b.position);
            //console.log(profileItems)
            const nextPayload = {target: "profiles", data: profileItems,};
            await commit("SET_PRODUCT_DATA", nextPayload);
        } catch (e) {
            console.log(e);
        }
    },
    async getSeries({commit}, payload) {
        try {
            const response = await BackendService.getSeries(payload.selection?.id);
            let seriesItems = response.data;
            //profileItems.sort((a, b) => a.position - b.position);
            //console.log(profileItems)
            const nextPayload = {target: "series", data: seriesItems,};
            await commit("SET_PRODUCT_ITEMS", nextPayload);
        } catch (e) {
            console.log(e);
        }
    },
    async getModel({commit, dispatch, rootState}, payload) {
        try {
            //console.log(payload);
            const response = await BackendService.getModel(payload.selection?.id);
            const nextPayload = {caller: payload.caller, data: response.data};
            commit("SET_MODEL_DATA", nextPayload);
            dispatch("configuration/enableFavorites", rootState.productData.model, {root: true,});
            payload.customerConfig = rootState.configuration.customerConfig;
            commit("FILTER_MODEL_DATA", payload);
            dispatch("configuration/prefillDimensionsData", {}, {root: true});
        } catch (e) {
            console.log(e);
        }
    },

    // this will be called to re-initialize the productData (e.g. filter out
    // openings based on the selected division) and fix the customer config
    // by removing selections that are now invalid
    async reInitializeProductsAndCustomerConfig({commit, dispatch, rootState}, payload) {
        dispatch("configuration/setConfigData", payload, {root: true});
        payload.customerConfig = rootState.configuration.customerConfig;
        commit("FILTER_MODEL_DATA", payload);
        payload.model = state.model;
        dispatch("configuration/fixCustomerConfig", payload, {root: true});
    },

    async setPriceFromApi({commit}, payload) {
        await commit("SET_CSS_LOADING_WOOCOMMERCE");
        await commit("SET_PRICE_FROM_API", payload);
        await commit("SET_DISCOUNT_FROM_API", payload);
        await commit("SET_PRICING_WOOCOMMERCE", this.state.configuration);
        await commit("REMOVE_CSS_LOADING_WOOCOMMERCE");
    },

    moveItemToStayVisible({commit, rootState}, payload) {
        payload.config = rootState.configuration.customerConfig;
        commit("MOVE_ITEM_TO_STAY_VISIBLE", payload);
    },

    toggleOptionalSelection({commit, dispatch}, payload) {
        commit("TOGGLE_OPTIONAL_SELECTION", payload);
        // this will toggle the optional categories in side the
        // customer config.. if an optional category will be set back to "skip"
        // the config item will be set to undefined
        let active = payload.value;
        let customerConfig_item = this.state.configuration.customerConfig[payload.itemType]?.id;
        if (customerConfig_item && customerConfig_item > 0 && active){
            console.log('');
        } else {
            dispatch("configuration/enableFavorites", { [payload.itemType]: state.model[payload.itemType] }, { root: true });
        }
    },
    setProductDataFromExistProduct({commit}) {
        let payload_product_data = JSON.parse(this.state.setup.productDataJson);
        commit("SET_PRODUCT_DATA_FROM_EXIST_PRODUCT", payload_product_data);
    },
};

export const getters = {
    descriptionShortMenu: (state) => (path) => {
        return state?.[path]?.menu
            ? state?.[path]?.menu?.[0]?.description_short_menu
            : state.model[path]?.menu?.[0]?.description_short_menu;
    },

    descriptionLongMenu: (state) => (path) => {
        return state?.[path]?.menu
            ? state?.[path]?.menu?.[0]?.description_long_menu
            : state.model[path]?.menu?.[0]?.description_long_menu;
    },
    labelMenu: (state) => (path) => {
        return state?.[path]?.menu
            ? state?.[path]?.menu?.[0]?.label_menu
            : state.model[path]?.menu?.[0]?.label_menu;
    },
};
