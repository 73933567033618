import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: baseUrlFromContainer(),
  routes,
});

function baseUrlFromContainer() {
  const container = document.getElementById("app");
  if (container) {
    return container.getAttribute("data-base-url") || "/";
  }
  return "/";
}

function extractLanguageFromContainer() {
  const container = document.getElementById("app");
  if (container) {
    return container.getAttribute("data-language") || "de";
  }
  return "de";
}

function setAppLanguage() {
  i18n.locale = extractLanguageFromContainer();
}

router.beforeEach((to, from, next) => {
  setAppLanguage();
  next();
});

export default router;
