import Vue from "vue";
import Vuex from "vuex";
import * as setup from "@/store/modules/setup";
import * as productData from "@/store/modules/productData";
import * as configuration from "@/store/modules/configuration";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    setup,
    productData,
    configuration,
  },
});
