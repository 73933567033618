import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/lang/index";

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "de",
  fallbackLocale: "de",
  messages,
  numberFormats,
});
