import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import "es6-promise/auto";
import vuetify from "./plugins/vuetify";
import { i18n } from "@/plugins/i18n";
import svgJS from "@/plugins/svg.js";
import loader from "vue-ui-preloader";
import 'vue-material-design-icons/styles.css';

Vue.use(svgJS);
Vue.use(loader);

Vue.mixin({
  methods: {
    $evaluate: (param) => eval("this." + param),
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  loader,
  render: (h) => h(App),
}).$mount("#app");
