import axios from "axios";
import store from "@/store/store";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization": process.env.VUE_APP_API_APP_KEY,
  },
});

// set loading to true for all api requests being started
apiClient.interceptors.request.use((request) => {
  let target = request.url.split("/")[1].split("-")[1];
  store.dispatch("productData/setApiRequest", { [target]: true });
  return request;
});

// set loading to false for all api requests being ended
apiClient.interceptors.response.use((response) => {
  let target = response.config.url.split("/")[1].split("-")[1];
  store.dispatch("productData/setApiRequest", { [target]: false });
  return response;
});

export default {
  getMaterials() {
    return apiClient.get(
      `/get-materials/${store.state.setup.apiLanguageId}/${store.state.setup.apiConfiguratorId}`
    );
  },
  getProfiles(seriesId) {
    return apiClient.get(
      `/get-profiles/${store.state.setup.apiLanguageId}/${store.state.setup.apiConfiguratorId}/${seriesId}`
    );
  },
  getSeries(materialId) {
    return apiClient.get(
        `/get-series/${store.state.setup.apiLanguageId}/${materialId}`
    );
  },
  getModel(profileId) {
    return apiClient.get(
      `/get-model/${store.state.setup.apiLanguageId}/${store.state.setup.apiConfiguratorId}/${profileId}`
    );
  },
  // not in progress
  checkSegment(profileId) {
    return apiClient.post(
      `/check-segment/${store.state.setup.apiLanguageId}/${profileId}`,
      {
        segment: "DK",
        model_id: "1",
        width: 500,
        height: 500,
      }
    );
  },
  getFinalPrice(payload) {
    return apiClient.post(`/get-price/`, payload);
  },
  getDataByUuid() {
    return apiClient.get(`/get-uuid/${store.state.setup.uuid}`);
  }
};
