import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// Vuetify.config.silent = true

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#0D314B",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        windowStroke: "#424242",
        windowGlassStroke: "#424242",
        windowFill: "#2d2c2c",
        windowGlass: "#e6eef7",
      },
      light: {
        primary: "#0D314B",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        windowStroke: "#424242",
        windowGlassStroke: "#424242",
        windowFill: "#FFFFFF",
        windowGlass: "#e6eef7",
      },
    },
  },
});
